<template>
  <div>
    <el-dialog title="添加教育局"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="100px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教育局名称"
                          prop="bureau_name">
              <el-input placeholder="请输入教育局名称"
                        v-model="form.bureau_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input placeholder="请输入描述"
                        v-model="form.bureau_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="试卷地区">
              <!-- <el-input style="width:200px"
                        v-model="form.paper_district" /> -->
              <el-select style="width:100%"
                         v-model="form.paper_district"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in disList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        bureau_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
      checkList: [],
      disList: []
    }
  },
  mounted () {
    this.getDisList()

  },
  methods: {
    getDisList () {
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.disList = res.data.list
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v2/bureau/create',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>