<template>
  <div>
    <el-dialog title="查看学校"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-table :data="list"
                border
                stripe
                style="width: 100%">
        <el-table-column prop="school_id"
                         width="100"
                         label="学校ID" />
        <el-table-column prop="school_name"
                         label="学校名称" />
        <el-table-column prop="school_desc"
                         label="学校描述" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      list: []
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    getInfo (bureau_id) {
      this.$http({
        url: '/api/v2/bureau/bureau_school',
        method: 'get',
        params: {
          bureau_id
        }
      }).then(res => {
        this.list = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>